<mat-tab-group #matTabGroup>
  <mat-tab>
    <button *ngIf="(appService.isMobile$ | async) === true && showCloseShipmentsTab" mat-icon-button (click)="closeShipmentsTab.emit()" class="back">
      <mat-icon [svgIcon]="'enthus-arrow_back'"></mat-icon>&nbsp;{{ translationService.translations.action.Back }}
    </button>
    <mat-selection-list
      *ngIf="!table || (appService.isMobile$ | async) === true; else tableView"
      class="portal-list"
      [class.close-shipments-tab]="showCloseShipmentsTab"
      [multiple]="false"
      (selectionChange)="showDetails($event); selectionList.deselectAll()"
      #selectionList
    >
      <ng-container *ngIf="shipments && (shipments | async) && (shipments | async).length > 0">
        <mat-list-option *ngFor="let shipment of shipments | async" [value]="shipment" [disabled]="!shipment.ContractorIsTrackable">
          <mat-icon [svgIcon]="'enthus-local_shipping'" matListItemAvatar></mat-icon>
          <p matListItemTitle>
            <span>&nbsp;{{ shipment.ContractorNameShort }}&nbsp;</span>
            <span>&nbsp;{{ shipment.ContractorServiceDescription }}&nbsp;</span>
          </p>
          <ng-container *ngIf="shipment.ContractorIsTrackable" matListItemLine>
            <p *ngIf="shipment.TrackingExpectedDeliveryDate">
              <span>{{ translationService.translations.labels.ExpectedDelivery }}:&nbsp;</span>
              <span>{{ shipment.TrackingExpectedDeliveryDate | date: translationService.currentDateFormat }}</span>
            </p>
            <p *ngIf="shipment.TrackingUpdated">
              <span>{{ translationService.translations.labels.LastUpdate }}:&nbsp;</span>
              <span>{{ shipment.TrackingUpdated | date: translationService.currentDateAndTimeFormat }}</span>
            </p>
          </ng-container>
          <p *ngIf="!shipment.ContractorIsTrackable" matListItemLine>{{ translationService.translations.information.NoTrackingAvailable }}</p>
          <mat-divider></mat-divider>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </mat-tab>
  <mat-tab>
    <button *ngIf="showBackButton" mat-icon-button (click)="backToList()" class="back">
      <mat-icon [svgIcon]="'enthus-arrow_back'"></mat-icon>&nbsp;{{ translationService.translations.action.Back }}
    </button>
    <portal-shipment-details [shipmentDetails]="selectedShipment" [showGeneralInfoHeader]="showGeneralInfoHeader"></portal-shipment-details>
  </mat-tab>
</mat-tab-group>

<ng-template #tableView>
  <ejs-grid
    *ngIf="shipments | async"
    #grid
    [allowResizing]="true"
    [allowSelection]="false"
    [dataSource]="shipments | async"
    [clipMode]="'EllipsisWithTooltip'"
    gridLines="Both"
  >
    <e-columns>
      <e-column [minWidth]="250" [width]="1" [clipMode]="'clip'" [textAlign]="'center'">
        <ng-template #template let-data>
          <button mat-flat-button class="show-shipment-details" (click)="handleRowSelection(data)" [disabled]="!data.ContractorIsTrackable">
            <mat-icon class="rotate-90deg-cw" [svgIcon]="'enthus-linear_scale'"></mat-icon>
            {{
              data.ContractorIsTrackable
                ? translationService.translations.labels.ShipmentDetails
                : translationService.translations.information.NoTrackingAvailable
            }}
          </button>
        </ng-template>
      </e-column>
      <e-column [minWidth]="200" [width]="1" field="ContractorName" headerText="{{ translationService.translations.headers.Shipment.Contractor }}"></e-column>
      <e-column [minWidth]="150" [width]="1" field="PackageTrackingNO" headerText="{{ translationService.translations.headers.Shipment.TrackingNO }}">
        <ng-template #template let-data>
          <span *ngIf="data.PackageTrackingNO">
            <a href="https://enthus.aftership.com/{{ data.PackageTrackingNO }}" target="_blank" class="link-text-color">{{ data.PackageTrackingNO }}</a>
          </span>
        </ng-template>
      </e-column>
      <e-column
        [minWidth]="150"
        [width]="1"
        field="ContractorServiceDescription"
        headerText="{{ translationService.translations.headers.Shipment.ModeOfTransport }}"
      ></e-column>
      <e-column
        [minWidth]="120"
        [width]="1"
        field="ShipmentInsertedDate"
        [format]="translationService.currentDateFormatOptions"
        type="date"
        headerText="{{ translationService.translations.headers.Shipment.ShippingDate }}"
      ></e-column>
      <e-column
        [minWidth]="250"
        [width]="1"
        field="ShippingLastStatus"
        type="string"
        headerText="{{ translationService.translations.headers.Shipment.ShippingLastStatus }}"
      >
        <ng-template #template let-data>
          {{ data.TrackingCheckpoints.length > 0 ? data.TrackingCheckpoints[data.TrackingCheckpoints.length - 1].Message : '' }}
        </ng-template>
      </e-column>
      <e-column
        [minWidth]="120"
        [width]="1"
        field="TrackingDeliveredDate"
        [format]="translationService.currentDateFormatOptions"
        type="date"
        headerText="{{ translationService.translations.headers.Shipment.DateOfArrival }}"
      ></e-column>
      <e-column [minWidth]="80" [width]="1" field="PackageLength" headerText="{{ translationService.translations.headers.Shipment.PackageLength }}"></e-column>
      <e-column [minWidth]="80" [width]="1" field="PackageWidth" headerText="{{ translationService.translations.headers.Shipment.PackageWidth }}"></e-column>
      <e-column [minWidth]="80" [width]="1" field="PackageHeight" headerText="{{ translationService.translations.headers.Shipment.PackageHeight }}"></e-column>
      <e-column [minWidth]="80" [width]="1" field="PackageWeight" headerText="{{ translationService.translations.headers.Shipment.PackageWeight }}"></e-column>
      <e-column
        [minWidth]="300"
        [width]="1"
        field="ShipmentCustomerReference"
        headerText="{{ translationService.translations.headers.Shipment.Remark }}"
      ></e-column>
    </e-columns>
  </ejs-grid>
</ng-template>
