import { Component, Input } from '@angular/core';
import { IShipmentDetails } from '../../interfaces/document.interface';
import { AppService } from '../../services/app.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'portal-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss'],
})
export class ShipmentDetailsComponent {
  @Input() public shipmentDetails: IShipmentDetails;
  @Input() public showGeneralInfoHeader = true;

  constructor(
    public translationService: TranslationService,
    public appService: AppService
  ) {}
}
