import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { MatTabGroup } from '@angular/material/tabs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { lastValueFrom, Observable, of, timer } from 'rxjs';
import { delay, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { IDocument, IShipmentDetails } from '../../interfaces/document.interface';
import { IGenericPosition } from '../../interfaces/generic-position.interface';
import { AppService } from '../../services/app.service';
import { DocumentClassId } from '../../services/document.service';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { ShipmentService } from '../../services/shipment.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'portal-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss'],
})
export class ShipmentListComponent implements OnInit, OnDestroy {
  @Input() public document: IDocument;
  @Input() public position: Partial<IGenericPosition>;
  @Input() public table = false;
  @Input() public showGeneralInfoHeader = true;
  public shipments: Observable<IShipmentDetails[]>;
  public selectedShipment: IShipmentDetails;
  @ViewChild('matTabGroup') public matTabGroup: MatTabGroup;
  @ViewChild('grid') public grid: GridComponent;
  public backButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() public goBack: EventEmitter<void> = new EventEmitter<void>();
  @Input() public showBackButton = false;
  @Output() public closeShipmentsTab: EventEmitter<void> = new EventEmitter<void>();
  @Input() public showCloseShipmentsTab = false;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public translationService: TranslationService,
    public appService: AppService,
    public errorHandlingService: ErrorHandlingService,
    private _shipmentService: ShipmentService
  ) {
    this.backButtonClicked.pipe(takeUntil(this._destroyEmitter)).subscribe(() => {
      if (this.matTabGroup.selectedIndex === 1) {
        this.backToList();
      } else {
        this.goBack.next();
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  public loadShipments(): Observable<IShipmentDetails[]> {
    const documentNo =
      this.document?.DocumentClassId === DocumentClassId.Invoice && this.document?.DocumentDeliveryNos.length > 0
        ? this.document?.DocumentDeliveryNos[0]
        : this.document?.DocumentNo;
    const endpoint =
      this.document && this.position
        ? this._shipmentService.getShipmentsForPosition(documentNo, this.position.PositionId)
        : this._shipmentService.getShipments(documentNo);

    return this.errorHandlingService.handleErrorWithBannerAndRetry<IShipmentDetails[]>({
      endpoint: endpoint.pipe(
        takeUntil(this._destroyEmitter),
        map((shipments) => {
          shipments.forEach((shippingDetails) => {
            shippingDetails.TrackingCheckpoints.reverse();
          });

          this.shipments = of(shipments);

          return shipments;
        }),
        delay(300),
        shareReplay(1)
      ),
      propertyModification: (shipments) => {
        of(shipments);
      },
      bannerTitle: this.translationService.translations.error.FetchShipmentList.toString(),
    });
  }

  public ngOnInit(): void {
    this.loadShipments().subscribe();
  }

  public showDetails($event: MatSelectionListChange): void {
    this.selectedShipment = $event.options.find((option) => option.selected).value;
    this.matTabGroup.selectedIndex = 1;
  }

  public backToList(): void {
    this.matTabGroup.selectedIndex = 0;

    lastValueFrom(timer(parseInt(this.matTabGroup.animationDuration, 10)).pipe(take(1)), { defaultValue: 0 }).then(() => {
      this.selectedShipment = null;
    });
  }

  public handleRowSelection(data: IShipmentDetails): void {
    this.selectedShipment = data;
    this.matTabGroup.selectedIndex = 1;
  }
}
