@if (showGeneralInfoHeader) {
  <div class="general-info">
    <h3>
      <span>&nbsp;{{ shipmentDetails?.ContractorNameShort }}&nbsp;</span>
      <span>&nbsp;{{ shipmentDetails?.ContractorServiceDescription }}&nbsp;</span>
    </h3>
    <h4>
      {{ shipmentDetails?.ContractorName }}
    </h4>
    @if (shipmentDetails?.TrackingUpdated) {
      <p class="last-update mat-small">
        {{ translationService.translations.labels.LastUpdate }}: {{ shipmentDetails?.TrackingUpdated | date: translationService.currentDateAndTimeFormat }}
      </p>
    }
  </div>
}
<div class="states">
  @for (checkpoint of shipmentDetails?.TrackingCheckpoints; track checkpoint; let index = $index; let last = $last) {
    <div class="state-row" [class.active]="last" [class.mobile]="(appService.isMobile$ | async) === true">
      <div class="left-column">
        <mat-icon [class.one-checkpoint]="shipmentDetails?.TrackingCheckpoints.length === 1" [svgIcon]="'enthus-lens'"></mat-icon>
      </div>
      <div class="center-column">
        @if ((appService.isMobile$ | async) === false) {
          <div class="date-location-status-info">
            <div><mat-icon [svgIcon]="'enthus-event'"></mat-icon>&nbsp;{{ checkpoint.CheckpointDate | date: translationService.currentDateFormat }}&nbsp;</div>
            <div><mat-icon [svgIcon]="'enthus-place'"></mat-icon>&nbsp;{{ checkpoint.Location }}&nbsp;</div>
          </div>
          <div class="detail-message-info"><mat-icon [svgIcon]="'enthus-note'"></mat-icon>&nbsp;{{ checkpoint.StatusDetailMessage }}&nbsp;</div>
          <div class="tracking-message mat-small">{{ checkpoint.Message }}</div>
        }
        @if ((appService.isMobile$ | async) === true) {
          <div>{{ checkpoint.Location }}</div>
          <div>{{ checkpoint.StatusDetailMessage }}</div>
        }
      </div>
    </div>
  }
</div>
