import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { ShipmentDetailsComponent } from './shipment-details/shipment-details.component';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';

@NgModule({
  declarations: [ShipmentDetailsComponent, ShipmentListComponent],
  exports: [ShipmentDetailsComponent, ShipmentListComponent],
  imports: [CommonModule, MatIconModule, MatTabsModule, MatListModule, MatButtonModule, MatTableModule, GridModule],
})
export class ShipmentInformationModule {}
